//
//
//  Home
//
//

import {useLogto} from "@logto/react"
import {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom";


function Home() {
    const [isLoading, setIsLoading] = useState(true)
    const [hasSubscription, setHasSubscription] = useState(false)
    const [userName, setUserName] = useState("")
    const navigate = useNavigate()
    const { signIn, signOut, isAuthenticated, getIdTokenClaims, getAccessToken} = useLogto()


    function goToManageSubscription() {
        getAccessToken(import.meta.env.VITE_LOGTO_API_ID).then(token => {
            fetch(import.meta.env.VITE_API_ENDPOINT + "/api/user/subscription/portal", {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => response.json()).then(data => {
                window.location.href = data["url"]
            })
        })
    }

    useEffect(() => {
        if (isAuthenticated) {
            getIdTokenClaims().then((claims) => {
                setUserName(claims?.name || "")
            })
        }
    }, [getIdTokenClaims, isAuthenticated])

    useEffect(() => {
        if (isAuthenticated) {
            // TODO: Check the subscription is currently done only in the home page but it should be done at the
            // beginning of the application not only in the home page
            getAccessToken(import.meta.env.VITE_LOGTO_API_ID).then(token => {
                return fetch(import.meta.env.VITE_API_ENDPOINT + "/api/user/subscription", {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            }).then(response => response.json()).then(data => {
                if (data["subscription"]) {
                    // It's a customer with an active subscription
                    setHasSubscription(true)
                } else {
                    if (data["first_customer"]) {
                        // It's not a previous customer, i.e. new to the platform, redirect to pricing page
                        navigate("/pricing")
                    } else {
                        // It's a previous customer but doesn't have any subscription active
                        setHasSubscription(false)
                    }
                }
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }, [isAuthenticated, getAccessToken, navigate])

    if (isAuthenticated) {
        if (isLoading) {
            return <p>Loading...</p>
        }

        return (
            <>
                {!hasSubscription &&
                    <p style={{backgroundColor: "red", color: "white"}}>
                        You don't have any subscription active. Subscribe to reactivate the service.
                        <button onClick={() => navigate("/pricing")}>Subscribe</button>
                    </p>
                }
                <h1>Hello {userName}</h1>
                <button onClick={goToManageSubscription}>Billing</button>
                <br/>
                <button onClick={() => signOut(window.location.origin + "/")}>Sign Out</button>
            </>
        )
    } else {
        return (
            <button onClick={() => signIn(window.location.origin + '/sign-in-callback')}>Sign In</button>
        )
    }
}

export default Home
