import './App.css'
import {Route, Routes} from "react-router-dom"
import Home from "./pages/Home"
import SignInCallback from "./pages/SignInCallback"
import Pricing from "./pages/Pricing";


function App() {
    return (
        <>
            <Routes>
                <Route index Component={Home}/>
                <Route path="/sign-in-callback" Component={SignInCallback}/>
                <Route path="/pricing" Component={Pricing}/>
            </Routes>
        </>
    )
}

export default App
